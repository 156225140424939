// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".exam-details-content {\n  background: #ffffff;\n  min-height: 240px;\n  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);\n  border-radius: 6px;\n  padding: 10px 24px;\n}\n.exam-details-content h2 {\n  font-family: Roboto;\n  font-size: 20px;\n  font-weight: 600;\n  color: #3d3d3d;\n  margin-bottom: 10px;\n}\n.exam-details-content h3 {\n  font-family: Roboto;\n  font-size: 16px;\n  font-weight: 700;\n  color: #545454;\n  margin-bottom: 4px;\n  margin-top: 26px;\n}\n.exam-details-content p {\n  font-family: Roboto;\n  margin-bottom: 14px !important;\n  color: #545454;\n  font-weight: 400;\n  text-align: justify;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
