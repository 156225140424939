var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _vm.singleQuestions.length > 0
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("exam-questions-table", {
                attrs: { "ielts-questions": _vm.singleQuestions, readonly: "" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.essays
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: { flat: "", multiple: "" },
                  model: {
                    value: _vm.panels,
                    callback: function ($$v) {
                      _vm.panels = $$v
                    },
                    expression: "panels",
                  },
                },
                _vm._l(_vm.essayQuestions, function (item, i) {
                  return _c(
                    "v-expansion-panel",
                    { key: i, staticClass: "mt-2" },
                    [
                      _c("v-expansion-panel-header", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center",
                          },
                          [
                            item.item_text.length > _vm.truncateTextAfter
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { disabled: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "div",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.item_text
                                                              .htmlToText()
                                                              .slice(
                                                                0,
                                                                _vm.truncateTextAfter
                                                              )
                                                          ) +
                                                          "....... "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.item_text.htmlToText())
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    class: _vm.panels.includes(i)
                                      ? "white--text"
                                      : "",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.item_text.htmlToText()) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.hasQuestionGroup(item)
                            ? _c("v-simple-table", [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v(_vm._s("Question"))]),
                                    _c("th", [_vm._v(_vm._s("Type"))]),
                                    _c("th", [_vm._v(_vm._s("Points"))]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    item.question_group,
                                    function (subItem, index) {
                                      return _c("tr", { key: index }, [
                                        _c(
                                          "td",
                                          [
                                            _c("question-group-viewer", {
                                              staticClass: "pa-2",
                                              attrs: {
                                                content: subItem.item_text,
                                                questions:
                                                  _vm.filterByQuestionGroup(
                                                    subItem,
                                                    item?.questions
                                                  ),
                                                answers:
                                                  _vm.filterByQuestionGroup(
                                                    subItem,
                                                    item?.answers
                                                  ),
                                                files: subItem?.files,
                                                readonly: true,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s("Group Question") + " "
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          _vm._l(
                                            _vm.filterByQuestionGroup(
                                              subItem,
                                              item?.questions
                                            ),
                                            function (q, j) {
                                              return _c("div", { key: j }, [
                                                _c("span", [
                                                  _vm._v(_vm._s(q.points)),
                                                ]),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _c("v-data-table", {
                                attrs: {
                                  headers: _vm.headers,
                                  items: item?.questions,
                                  "items-per-page": 1000,
                                  "item-key": "question_id",
                                  "hide-default-footer": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: `item.text`,
                                      fn: function ({ item }) {
                                        return [
                                          item.questionDetails.text
                                            ? _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.questionDetails.text
                                                  ),
                                                },
                                              })
                                            : item.questionDetails.file_url
                                            ? _c("v-img", {
                                                attrs: {
                                                  contain: "",
                                                  "max-width": "300",
                                                  "max-height": "300",
                                                  src: item.questionDetails
                                                    .file_url,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.topics`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.questionDetails.topics
                                                  .map((topic) => topic.text)
                                                  .join(", ")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.type`,
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " + _vm._s(item.points) + " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                        ],
                        1
                      ),
                      _c("v-divider"),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }