<template>
  <m-container>
    <page-title
      previous-location
      :page-title="!$language ? 'Exam Details' : 'পরীক্ষার বিবরণ'"
    ></page-title>

    <exam-details :exam="exam" :exam-questions="examQuestions">
      <template v-slot:exam-details-action>
        <m-container>
          <m-row justify="end">
            <!--<m-button
              small
              outlined
              rounded
              color="primary"
              :to="{ name: 'exam-edit', params: { examId: examId } }"
              ><m-icon left>edit</m-icon> Edit</m-button
            >-->
            <!--            <m-button-->
            <!--              color="red accent-3"-->
            <!--              class="ml-3"-->
            <!--              small-->
            <!--              outlined-->
            <!--              rounded-->
            <!--              :loading="deleting"-->
            <!--              @click="deleteExam"-->
            <!--              ><m-icon left>delete</m-icon>Delete</m-button-->
            <!--            >-->
          </m-row>
        </m-container>
      </template>
    </exam-details>
  </m-container>
</template>

<script>
import ExamDetails from "#ecf/exam/components/ExamDetails";
import { ExamProvider } from "#ecf/exam/mixins";
import { Logger } from "/global/utils/helpers";
export default {
  name: "ExamDetailsPage",
  components: { ExamDetails },
  mixins: [ExamProvider],
  data() {
    return {
      deleting: false
    };
  },
  inject: ["$courseOwnerId", "$courseId"],
  computed: {},
  created() {
    this.getExam(this.examId, this.$courseId(), this.$courseOwnerId());
  },
  methods: {
    async deleteExam() {
      try {
        this.deleting = true;
        await this.$store.dispatch("exam/delete", { exam: this.exam });
        await this.$router.replace({ name: "exam-list" });
      } catch (e) {
        Logger.log(e);
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>

<style></style>
