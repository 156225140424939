var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _vm.exam
        ? _c(
            "m-container",
            {
              staticClass:
                "exam-details-content border-solid border-1-2 border-primary",
            },
            [
              _vm._t("exam-details-action"),
              _c(
                "m-container",
                { staticClass: "py-0" },
                [
                  _c("h2", [_vm._v(_vm._s(_vm.exam.title))]),
                  _c("m-container", { staticClass: "pa-0" }, [
                    _c("h3", [
                      _vm._v(
                        _vm._s(!_vm.$language ? "Instructions:" : "নির্দেশাবলী")
                      ),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.exam.instruction))]),
                    _c("h3", [
                      _vm._v(
                        _vm._s(!_vm.$language ? "Total marks:" : "পয়েন্ট")
                      ),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.exam.points))]),
                    _c("h3", [
                      _vm._v(_vm._s(!_vm.$language ? "Duration:" : "সময়কাল")),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.exam.duration) +
                          " " +
                          _vm._s(!_vm.$language ? "minutes" : "মিনিট")
                      ),
                    ]),
                  ]),
                  !_vm.isSectionExam
                    ? _c("exam-questions", {
                        staticClass: "mb-3",
                        attrs: {
                          questions: _vm.sectionQuestions,
                          essays: _vm.sectionEssays,
                        },
                      })
                    : _c(
                        "v-expansion-panels",
                        {
                          attrs: { flat: "", multiple: "" },
                          model: {
                            value: _vm.panels,
                            callback: function ($$v) {
                              _vm.panels = $$v
                            },
                            expression: "panels",
                          },
                        },
                        _vm._l(_vm.examSections, function (section, index) {
                          return _c(
                            "v-expansion-panel",
                            {
                              key: index,
                              staticClass:
                                "mb-3 border border-1-2 border-solid",
                              staticStyle: { "border-color": "#b7b7b7" },
                            },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  class: _vm.panels.includes(index)
                                    ? "border-1-2 border-solid border-primary"
                                    : "",
                                  attrs: {
                                    color: _vm.panels.includes(index)
                                      ? "#D6F3FF"
                                      : "white",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        justify: "space-between",
                                      },
                                    },
                                    [
                                      _c("h3", { staticClass: "ma-0" }, [
                                        _vm._v(_vm._s(section.title)),
                                      ]),
                                      _c("h3", { staticClass: "mr-10 mt-0" }, [
                                        _vm._v(
                                          " Duration: " +
                                            _vm._s(section.duration) +
                                            " minutes "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("exam-questions", {
                                    attrs: {
                                      questions:
                                        _vm.sectionQuestions[section.id],
                                      essays: _vm.sectionEssays[section.id],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }