<template>
  <v-card flat>
    <v-card v-if="singleQuestions.length > 0" flat>
      <exam-questions-table
        :ielts-questions="singleQuestions"
        readonly
      ></exam-questions-table>
    </v-card>
    <v-card v-if="essays" flat>
      <v-expansion-panels v-model="panels" flat multiple>
        <v-expansion-panel
          v-for="(item, i) in essayQuestions"
          :key="i"
          class="mt-2"
        >
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between align-center">
              <div v-if="item.item_text.length > truncateTextAfter">
                <v-tooltip disabled>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{
                        item.item_text.htmlToText().slice(0, truncateTextAfter)
                      }}.......
                    </div>
                  </template>
                  <span>{{ item.item_text.htmlToText() }}</span>
                </v-tooltip>
              </div>
              <div v-else :class="panels.includes(i) ? 'white--text' : ''">
                {{ item.item_text.htmlToText() }}
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table v-if="hasQuestionGroup(item)">
              <thead>
                <tr>
                  <th>{{ "Question" }}</th>
                  <th>{{ "Type" }}</th>
                  <th>{{ "Points" }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(subItem, index) in item.question_group"
                  :key="index"
                >
                  <td>
                    <question-group-viewer
                      class="pa-2"
                      :content="subItem.item_text"
                      :questions="
                        filterByQuestionGroup(subItem, item?.questions)
                      "
                      :answers="filterByQuestionGroup(subItem, item?.answers)"
                      :files="subItem?.files"
                      :readonly="true"
                    ></question-group-viewer>
                  </td>
                  <td>
                    {{ "Group Question" }}
                  </td>
                  <td>
                    <div
                      v-for="(q, j) in filterByQuestionGroup(
                        subItem,
                        item?.questions
                      )"
                      :key="j"
                    >
                      <span>{{ q.points }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-data-table
              v-else
              :headers="headers"
              :items="item?.questions"
              :items-per-page="1000"
              item-key="question_id"
              hide-default-footer
            >
              <template v-slot:[`item.text`]="{ item }">
                <div
                  v-if="item.questionDetails.text"
                  v-html="item.questionDetails.text"
                ></div>
                <v-img
                  v-else-if="item.questionDetails.file_url"
                  contain
                  max-width="300"
                  max-height="300"
                  :src="item.questionDetails.file_url"
                ></v-img>
              </template>
              <template v-slot:[`item.topics`]="{ item }">
                {{
                  item.questionDetails.topics
                    .map(topic => topic.text)
                    .join(", ")
                }}
              </template>
              <template v-slot:[`item.type`]="{ item }">
                {{ item.points }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
          <v-divider></v-divider>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-card>
</template>

<script>
import ExamQuestionsTable from "#ecf/exam/components/ExamQuestionsTable.vue";
import QuestionGroupViewer from "#ecf/question-bank/components/GroupQuestion/QuestionGroupViewer.vue";
import _ from "lodash";

const HEADERS = [
  {
    text: "Question Text or Image",
    value: "text",
    width: "40%",
    sortable: false
  },
  {
    text: "Topic(s)",
    align: "center",
    value: "topics",
    width: "15%",
    sortable: false
  },
  { text: "Point", align: "center", value: "points", sortable: false }
];
export default {
  name: "ExamQuestions",
  components: { QuestionGroupViewer, ExamQuestionsTable },
  props: {
    questions: {
      type: Array,
      default: () => []
    },
    essays: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      panels: [],
      openPanels: [],
      headers: HEADERS
    };
  },
  computed: {
    truncateTextAfter() {
      return Math.floor(this.$vuetify.breakpoint.width * 0.08);
    },
    singleQuestions() {
      let singleQues = [];
      this.questions?.forEach(question => {
        if (question.essay_id === undefined || question.essay_id === null) {
          singleQues.push(question);
        }
      });
      return singleQues;
    },
    essayQuestions() {
      let essayQues = Array.from(this.essays);
      essayQues.forEach(item => {
        item["questions"] = this.questions?.filter(
          questions => questions.essay_id === item.id
        );
      });
      return essayQues;
    }
  },
  methods: {
    filterByQuestionGroup(questionGroup, questions) {
      return questions?.filter(item => item.collection_id === questionGroup.id);
    },
    hasQuestionGroup(data) {
      return (data["question_group"]?.length || -1) > 0;
    },
    togglePanel(i) {
      if (!this.openPanels.includes(i)) {
        this.openPanels.push(i);
      } else {
        this.openPanels = this.openPanels.filter(item => item !== i);
      }
    },
    getQuestionsForEssay(i) {
      let essayQuestions = [];
      this.questions.forEach(question => {
        if (question?.essay_id === this.essayQuestions[i].id) {
          essayQuestions.push(question);
        }
      });
      this.$set(this.essayQuestions[i], "questions", essayQuestions);
    }
  }
};
</script>

<style scoped lang="scss"></style>
