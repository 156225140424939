<template>
  <m-container>
    <m-container
      v-if="exam"
      class="exam-details-content  border-solid border-1-2 border-primary"
    >
      <slot name="exam-details-action"></slot>
      <m-container class="py-0">
        <h2>{{ exam.title }}</h2>
        <m-container class="pa-0">
          <h3>{{ !$language ? "Instructions:" : "নির্দেশাবলী" }}</h3>
          <p>{{ exam.instruction }}</p>
          <h3>{{ !$language ? "Total marks:" : "পয়েন্ট" }}</h3>
          <p>{{ exam.points }}</p>
          <h3>{{ !$language ? "Duration:" : "সময়কাল" }}</h3>
          <p>{{ exam.duration }} {{ !$language ? "minutes" : "মিনিট" }}</p>
        </m-container>
        <exam-questions
          v-if="!isSectionExam"
          class="mb-3"
          :questions="sectionQuestions"
          :essays="sectionEssays"
        ></exam-questions>
        <v-expansion-panels v-else v-model="panels" flat multiple>
          <v-expansion-panel
            v-for="(section, index) in examSections"
            :key="index"
            class="mb-3 border border-1-2 border-solid"
            style="border-color: #b7b7b7"
          >
            <v-expansion-panel-header
              :color="panels.includes(index) ? '#D6F3FF' : 'white'"
              :class="
                panels.includes(index)
                  ? 'border-1-2 border-solid border-primary'
                  : ''
              "
            >
              <v-row no-gutters justify="space-between">
                <h3 class="ma-0">{{ section.title }}</h3>
                <h3 class="mr-10 mt-0">
                  Duration: {{ section.duration }} minutes
                </h3>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <exam-questions
                :questions="sectionQuestions[section.id]"
                :essays="sectionEssays[section.id]"
              ></exam-questions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </m-container>
    </m-container>
  </m-container>
</template>

<script>
import { EquationFinder } from "#ecf/question-bank/mixins";
import ExamQuestions from "#ecf/exam/components/ExamQuestions.vue";

export default {
  name: "ExamDetails",
  components: { ExamQuestions },
  mixins: [EquationFinder],
  props: {
    exam: {
      type: Object,
      default: () => ({})
    },
    examQuestions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modifiedExamQuestions: [],
      renderExamQuestionsTable: false,
      renderNow: false,
      panels: [],
      openPanels: []
    };
  },
  computed: {
    isSectionExam() {
      return this.exam?.sections?.length > 0;
    },
    examSections() {
      let out = [...this.exam?.sections];
      out.sort((a, b) => {
        return a.order - b.order;
      });
      return out;
    },
    sectionQuestions() {
      if (!this.isSectionExam) {
        return this.exam?.questions;
      }
      let questions = {};
      this.exam?.questions?.forEach(question => {
        if (question.section_id in questions)
          questions[question.section_id].push(question);
        else questions[question.section_id] = [question];
      });
      return questions;
    },
    sectionEssays() {
      if (!this.isSectionExam) {
        return this.exam?.essays;
      }
      let essays = {};
      for (let sectionId in this.sectionQuestions) {
        let temp = new Set();
        this.sectionQuestions[sectionId]?.forEach(question => {
          temp.add(question.essay_id);
        });
        let essayIds = Array.from(temp);
        essays[sectionId] = this.exam?.essays?.filter(essay =>
          essayIds.includes(essay.id)
        );
      }
      return essays;
    }
  },

  // watch: {
  //   examQuestions(value) {
  //     this.modifiedExamQuestions = value;
  //     this.renderExamQuestionsTable = true;
  //   }
  // },
  async created() {
    try {
      // await this.retrieveEquationsAndModifyQuestions(
      //   this.modifiedExamQuestions
      // );
      this.renderNow = true;
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message]);
    }
  },
  methods: {
    togglePanel(i) {
      if (!this.openPanels.includes(i)) {
        this.openPanels.push(i);
      } else {
        this.openPanels = this.openPanels.filter(item => item !== i);
      }
    }
  }
};
</script>

<style lang="scss">
.exam-details-content {
  background: #ffffff;
  min-height: 240px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 10px 24px;

  h2 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    color: $text;
    margin-bottom: 10px;
  }

  h3 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    color: #545454;
    margin-bottom: 4px;
    margin-top: 26px;
  }

  p {
    font-family: Roboto;
    margin-bottom: 14px !important;
    color: #545454;
    font-weight: 400;
    text-align: justify;
  }
}
</style>
