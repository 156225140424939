var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "Exam Details" : "পরীক্ষার বিবরণ",
        },
      }),
      _c("exam-details", {
        attrs: { exam: _vm.exam, "exam-questions": _vm.examQuestions },
        scopedSlots: _vm._u([
          {
            key: "exam-details-action",
            fn: function () {
              return [
                _c(
                  "m-container",
                  [_c("m-row", { attrs: { justify: "end" } })],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }